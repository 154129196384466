import { Injectable } from '@angular/core';
import { initializeApp, FirebaseApp } from 'firebase/app';
import { getFirestore, Firestore, collection, addDoc } from 'firebase/firestore';
import { ConfigService } from './config-service.service';

@Injectable({
  providedIn: 'root',
})
export class MailService {
  private app: FirebaseApp | null = null;
  private db: Firestore | null = null;

  constructor(private configService: ConfigService) {
    const firebaseConfig = this.configService.firebaseConfig;

    if (firebaseConfig) {
      // Initialize Firebase
      this.app = initializeApp(firebaseConfig);
      this.db = getFirestore(this.app);
    } else {
      console.error('Firebase configuration is missing.');
    }
  }

  async sendMail(name: string, email: string, subject: string): Promise<void> {
    if (!this.db) {
      console.error('Firestore is not initialized.');
      return;
    }

    try {
      await addDoc(collection(this.db, 'mail'), {
        to: ['office@purpleturtle.dev'],
        from: 'no-reply@purpleturtle.dev',
        message: {
          subject: 'Site Contact Form Received',
          html: `
            <div><b>Name:</b> ${name}</div>
            <div><b>Email:</b> ${email}</div>
            <div><b>Message:</b> ${subject}</div>
          `,
        },
      });
    } catch (e) {
      console.error('Error sending mail:', e);
    }
  }
}
