import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { FormsModule } from '@angular/forms';
import { CountUpModule } from 'ngx-countup';
import { NgxTypedJsModule } from 'ngx-typed-js';
import { TooltipModule } from 'ng2-tooltip-directive';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutComponent } from './layout/layout.component';
import { NavbarComponent } from './components/common/navbar/navbar.component';
import { OurServicesComponent } from './components/common/our-services/our-services.component';
import { TechnologiesComponent } from './components/common/technologies/technologies.component';
import { PartnersComponent } from './components/common/partners/partners.component';
import { AboutUsComponent } from './components/common/about-us/about-us.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { FeedbackComponent } from './components/common/feedback/feedback.component';
import { ContactComponent } from './components/common/contact/contact.component';

import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { environment } from '../environments/environment';

import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { PrivacyPolicyComponent } from './components/common/privacy-policy/privacy-policy.component';
import { ConfigService } from './services/config-service.service';
import { HttpClientModule } from '@angular/common/http';

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: 'purpleturtle.dev',
  },
  position: 'bottom-left',
  theme: 'classic',
  palette: {
    popup: {
      background: '#000000',
      text: '#ffffff',
      link: '#ffffff',
    },
    button: {
      background: '#f1d600',
      text: '#000000',
      border: 'transparent',
    },
  },
  type: 'info',
  content: {
    message:
      'We use cookies to improve your experience and deliver personalized content. By using PurpleTurtle, you agree to our ',
    dismiss: 'Got it!',
    deny: 'Refuse cookies',
    link: 'Cookie Policy',
    href: 'https://purpleturtle.dev/privacy-policy',
    policy: 'Cookie Policy',
    target: '_self',
  },
};

// Function to initialize the app
export function initializeApp(configService: ConfigService) {
  return () => configService.loadConfig();
}

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    NavbarComponent,
    OurServicesComponent,
    TechnologiesComponent,
    PartnersComponent,
    AboutUsComponent,
    FooterComponent,
    FeedbackComponent,
    ContactComponent,
    PrivacyPolicyComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgxScrollTopModule,
    CarouselModule,
    FormsModule,
    CountUpModule,
    TooltipModule,
    NgxTypedJsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    HttpClientModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
  ],
  providers: [
    ConfigService, // Ensure ConfigService is provided
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [ConfigService],
      multi: true,
    },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.recaptcha.siteKey,
      } as RecaptchaSettings,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
